.sidebar {
  @media (max-width: 767px) {
    width: 177px !important;
    max-width: 177px !important;
    min-width: 177px !important;
    position: absolute;
    top: 0;
    height: 100%;
    background-color: $theme-contrast;
    z-index: 9;

    // .menu-name {
    //   display: none;
    // }
    .logoSec {
      // min-height: 74px !important;
      margin: 0;
      padding-left: 2px;
      .logo {
        padding: 0;
      }
    }
  }
}
.active .sidebar .menu-item {
  min-width: fit-content;
}
