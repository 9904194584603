dialog::backdrop {
  // background-color: none;
  // display: none;
  background-color: $theme-black-400;
}
dialog {
  display: flex;
  flex-direction: column;
  dialog-body,
  aside-dialog-body {
    overflow: auto;
    position: relative;
    flex: 1 1 auto;
    padding: 0rem;
  }
  &[data-aside-modal] {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    height: 100%;
    max-height: 100%;
    border-radius: 0 0.5rem 0.5rem 0;
    margin-right: auto;
    margin-left: 0;
  }

  &[data-aside-modal='right'] {
    border-radius: 0.5rem 0 0 0.5rem;
    animation: slideRight 0.3s forwards;
    margin-left: auto;
    margin-right: 0;
  }
  .dialogScroll {
    min-height: calc(100vh - 113px);
    max-height: calc(100vh - 113px);
    @media (max-width: 767px) {
      min-height: auto;
      max-height: calc(100vh - 200px);
    }
  }
  @media (max-width: 767px) {
    max-width: 350px;
    min-width: 350px !important;
    &.w-min--600,
    &.w-min--700 {
      min-width: 350px;
    }
  }
}

@keyframes slideRight {
  0% {
    margin-right: -100%;
  }
  100% {
    margin-right: 0;
  }
}
