.loginPage {
  z-index: 1;
  min-height: 100vh;
  &.m-t--64 {
    padding-top: 54px;
  }
  .inputIcon {
    bottom: 10px;
    svg {
      width: 20px;
    }
  }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   max-width: 50%;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   z-index: 0;
  //   background: url("../../assets/images/Loginbg.svg") no-repeat left top;
  //   background-size: cover;
  // }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 200px;
  //   max-width: 258px;
  //   height: 100%;
  //   max-height: 385px;
  //   top: 30%;
  //   right: 0;
  //   z-index: 0;
  //   background: url('../../assets/images/logincircle.svg') no-repeat left top;
  //   background-size: contain;
  // }

  .loginContent {
    min-height: calc(100vh - 61px);
  }
  @media (max-width: 767px) {
    .d-none--xs {
      display: none;
    }
    &.verification-iframe {
      .logoConst {
        max-width: 100px;
      }
      .myAccountBtn {
        top: 11px;
        right: 122px;
        max-width: 108px;
      }
    }
  }
  &.mainSign {
    padding-top: 54px;
  }
}
