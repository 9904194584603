.spinner {
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  position: relative;

  &--xs {
    border: 0.2rem solid $theme-primary-400;
    border-top: 2.3px solid $theme-primary;
    width: 1.2rem;
    height: 1.2rem;
  }
  &--sm {
    border: 0.2rem solid $theme-primary-400;

    border-top: 3px solid $theme-primary;
    width: 1.5rem;
    height: 1.5rem;
  }
  &--md {
    border: 0.4rem solid $theme-primary-400;
    border-top: 5px solid $theme-primary;
    width: 3rem;
    height: 3rem;
  }
  &--lg {
    border: 0.6rem solid $theme-primary-400;
    border-top: 7px solid $theme-primary;
    width: 4rem;
    height: 4rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
