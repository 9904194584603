.profilePage {
  .preeCode {
    max-height: calc(100vh - 194px);
  }
  .claimWebhookList {
    .table--responsive {
      min-height: calc(100vh - 383px);
      max-height: calc(100vh - 383px);
    }
  }
}
