.planPricingPage {
  .container {
    max-width: 80%;
    margin: 0 auto;
    @media (max-width: 767px) {
      max-width: 96%;
    }
  }
  .sectionBanner {
    background-image: linear-gradient(90deg, var(--primary), var(--green));
    @media (max-width: 767px) {
      min-height: 400px;
    }
    .curveBottom {
      bottom: -4px;
    }
    .crewBlack {
      z-index: 1;
      span {
        background: linear-gradient(270deg, #14ef86 0.4%, #15ede7 100.57%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) rotate(-0deg) skew(-30deg);
        background: $theme-black;
        z-index: -1;

        @media (max-width: 767px) {
          max-width: 80%;
        }
      }
    }
  }
  .priceSection {
    margin-top: -350px;
    @media (max-width: 767px) {
      margin-top: -200px;
    }
    .badge {
      background: var(
        --brand-style,
        linear-gradient(270deg, $theme-green 0.4%, $theme-primary 100.57%)
      );
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .shadow--green {
      &::after {
        content: '';
        position: absolute;
        bottom: -50px;
        border-radius: 1248px;
        width: 100%;
        height: 15px;
        z-index: -1;
        opacity: 0.6;
        background: var(
          --brand-style,
          linear-gradient(270deg, #14ef86 0.4%, #15ede7 100.57%)
        );
        filter: blur(50px);
      }
    }
  }
  .newlatterSection {
    background-image: linear-gradient(90deg, var(--primary), var(--green));
    transform-origin: 50%;
    -webkit-text-fill-color: inherit;
    -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
    ::placeholder {
      font-size: 16px;
      color: $theme-black-600;
    }
    @media (max-width: 767px) {
      padding: 20px 10px;
    }
  }
  @media (max-width: 767px) {
    .font--3xl {
      font-size: 16px;
    }
    .font--8xl {
      font-size: 28px;
    }
    .font--5xl {
      font-size: 24px;
    }
  }
}
