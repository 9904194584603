header {
  .inputIcon {
    left: 15px;
    top: 10px;
  }

  @media (max-width: 767px) {
    // min-height: 74px !important;
    // max-height: 74px;
    .headerDropDown {
      dropdown-element {
        min-width: auto;
      }
    }

    .left--14p {
      left: 160px;
    }
    .topTitle {
      position: absolute;
      top: 70px;
      left: 0;
    }
    .btn {
      padding: 5px 7px;
      min-height: 20px;
    }
    .toggle-sidebar-icon {
      display: none;
    }
  }
}
