.table--responsive {
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 199px);
  // height: calc(100vh - 190px);
  // height: calc(100vh - 199px);
  .table {
    width: 100%;
    border-collapse: collapse;
    thead {
      position: sticky;
      top: -1px;
      z-index: 1;
    }
    th,
    td {
      padding: 6px;
      height: 40px;
      font-size: 13px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
    th {
      border: 1px;
      border-style: solid;
      font-weight: 500;

      background: $theme-contrast;
      color: $theme-secondary;
      border-color: $theme-black-100;
      white-space: nowrap;
      &:first-child {
        border-radius: 6px 0 0 0;
        border-left: none;
      }
      &:last-child {
        border-radius: 0px 6px 0 0;
        border-right: none;
      }
    }
    td {
      border: 0.9px;
      border-style: solid;

      white-space: nowrap;
      color: $theme-black;
      border-color: $theme-black-100;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }

    &.bordered {
      & > :not(caption) > * > * {
        border-width: 1px;
      }
      th,
      td {
        border-color: inherit;
        border-style: solid;
        border-width: 1px;
      }
    }
    &.radius--sm {
      th {
        border-radius: 6px 6px 0 0;
      }
    }
  }
  &--full-scroll {
    max-height: calc(100vh - 168px);
    // min-height: calc(100vh - 168px);
  }
  &--search-scroll {
    max-height: calc(100vh - 235px);
    min-height: calc(100vh - 235px);
    // min-height: calc(100vh - 192px);
  }
  .paginationTable {
    border-radius: 0 0 6px 6px;
    border-top: none;
  }
  &.radius--sm {
    border-radius: 6px 6px 0 0;
  }
}
.paginationTable {
  border-radius: 0 0 6px 6px;
  border-top: none;
}
// .thead-doubble-tr {
//   .table {
//     tr {
//       &:first-child {
//         th {

//           height: 32px;
//         }
//       }
//       &:nth-child(2) {
//         th {
//           padding-top: 0px;
//           height: 32px;
//         }
//       }
//     }
//   }
// }
