.form--control {
  background-color: transparent;
  // border-color: $theme-black-100;
  color: $theme-surface;
  &::placeholder {
    font-size: 12px;
    color: $theme-surface;
    font-weight: 400;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &.bottomBorderFocus {
    &:focus {
      box-shadow: none;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  &:has(.PhoneInputInput) {
    // padding: 0;
    padding-left: 10px;
  }
  // &.PhoneInput {
  //   &--focus {
  //     border-color: $theme-primary;
  //     box-shadow: 0 0 5px rgba($theme-primary, 0.3);
  //   }

  //   .PhoneInputInput {
  //     border: none;
  //     min-height: 36px;
  //     height: 100%;
  //     padding: 0.5rem 1rem;
  //     border-radius: 8px;
  //     &:focus {
  //       outline: none;
  //       box-shadow: none;
  //     }
  //   }
  // }
  &.otp-input-control {
    border-color: $theme-primary !important;
  }
  &.PhoneInput {
    position: relative;
    &--disabled {
      background: $theme-contrast;
      color: $theme-black-400;
      cursor: not-allowed;
    }
    .PhoneInputCountry {
      max-width: 40px;
      min-width: 40px;
      .PhoneInputCountrySelect {
        border: 0;
        cursor: pointer;
        height: 100%;
        left: 15px;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        max-width: 40px;
        min-width: 40px;
        padding-right: 0;
        appearance: auto;
        z-index: -1;
      }
      .PhoneInputCountryIcon {
        max-width: 30px;
        img {
          border-radius: 6px;

          width: 100%;
        }
      }
    }
    .PhoneInputInput {
      border: none;
      height: 38px;
      width: 100%;
      background-color: transparent;
      border-color: $theme-black-100;
      color: $theme-surface;
      &:focus {
        border: none;
        box-shadow: none;
      }

      &:disabled {
        background: $theme-contrast;
        color: $theme-black-400;
        cursor: not-allowed;
        &::placeholder {
          color: $theme-black-400;
        }
      }
    }
  }
  &[type='radio'],
  &[type='checkbox'] {
    min-height: 16px;
    min-width: 16px;
    border-color: $theme-light-surface;
  }

  &[type='file']::-webkit-file-upload-button {
    background: $theme-black-100;
    border: none;
    color: $theme-black;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    // color: $theme-grey;
    height: 28px;
    margin-top: 3px;
    margin-left: -8px;
  }
  &.no--focus {
    border: none;
    box-shadow: none;
  }
  &.link {
    &::placeholder {
      color: $theme-primary;
    }
  }
}

input,
textarea,
select {
  accent-color: $theme-primary;
  &::placeholder,
  &:required:invalid {
    font-size: 12px;
    // color: $theme-grey;
    font-weight: 400;
  }

  &:focus {
    outline: none;
    border-color: $theme-primary;
    box-shadow: 0 0 5px rgba($theme-primary, 0.3);
  }
  &[type='radio'] {
    &:focus {
      box-shadow: none;
    }
  }
  &:disabled {
    background: $theme-contrast;
    color: $theme-black-400;
    &::placeholder {
      color: $theme-black-400;
    }
  }
}

.form-control__select__control {
  background-color: transparent !important;
  border-color: $theme-black-100 !important;
  color: $theme-surface !important;
  &::placeholder {
    font-size: 12px !important;
    color: $theme-surface !important;
    font-weight: 400 !important;
  }
}
.async-select-input {
  .react-select-input__control--is-focused {
    border-color: $theme-primary !important;
    box-shadow: none;
  }
  .react-select-input__control {
    min-height: 40px;
    border-radius: 0.5rem;
    border-color: $theme-black-200;
  }
  .react-select-input__menu {
    .react-select-input__option--is-focused {
      background-color: $theme-primary-100;
    }
    .react-select-input__option--is-selected {
      background-color: $theme-primary;
      color: $theme-black;
    }
  }
}
.epr-search-container {
  input {
    &:focus,
    &:active {
      outline: none;
      border-color: $theme-primary;
      box-shadow: 0 0 5px rgba($theme-primary, 0.3);
    }
  }
}
