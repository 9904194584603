::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $theme-contrast;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $theme-contrast;
}

::-webkit-scrollbar-thumb {
  background-color: $theme-primary;
  border: 1px solid $theme-contrast;
}
.mainContent {
  max-height: calc(100vh - 0px);
  max-width: calc(100vw - 200px);
  width: 100%;
  transition: all 0.3s ease-in-out;
  // margin-left: 200px;
  @media (max-width: 767px) {
    max-width: calc(100% - 00px);
  }
}
.middle-section {
  &.active {
    .mainContent {
      max-width: calc(100vw - 60px);
      transition: all 0.3s ease-in-out;
      // margin-left: 60px;
    }
  }
}

.box--shadow {
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.05);
  &--xs {
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  }
}
.w-max--100p {
  max-width: 100%;
}
.w--auto {
  width: auto !important;
}
.flex {
  &--wrap {
    flex-wrap: wrap;
  }
  &--column {
    flex-direction: column;
  }
  &--shrink {
    &-3 {
      flex-shrink: 3;
    }
    &-2 {
      flex-shrink: 2;
    }
    &-1 {
      flex-shrink: 1;
    }
  }
}
.white--space-none {
  white-space: nowrap;
}
.resize-none {
  resize: none;
}
.m {
  &--auto {
    margin: auto;
  }
  &-l--auto {
    margin-left: auto;
  }
  &-x--auto {
    margin-left: auto;
    margin-right: auto;
  }
}
.decoration {
  &--none {
    text-decoration: none;
  }
}
.border {
  &--4 {
    border-width: 4px;
  }
  &--0 {
    border: none !important;
  }
  &--dashed {
    border-style: dashed !important;
  }
  // &--bottom {
  //   border-bottom: 1px solid;
  // }
}

.rotate--135 {
  transform: rotate(135deg);
}
.overflow {
  &--auto {
    overflow: auto;
  }
  &--y-auto {
    overflow-y: auto;
  }
  &--x-auto {
    overflow-x: auto;
  }
  &--hidden {
    overflow: hidden;
  }
}
.line-height {
  &--1 {
    line-height: 1;
  }
  &--1-dot-5 {
    line-height: 1.5;
  }
  &--2 {
    line-height: 2;
  }
}
.grid {
  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &--3 {
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: 1800px) {
      &--xl {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    @media (max-width: 767px) {
      &--xs {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &--4 {
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 767px) {
      &--xs {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.searchFilter {
  .inputIcon {
    top: 12px;
    left: 12px;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &--80 {
    max-width: 80px;
    @media (max-width: 1280px) {
      max-width: 60px;
      min-width: 60px;
    }
  }
}
.top {
  &---4 {
    top: -4px;
  }
  &---6 {
    top: -6px;
  }
  &---8 {
    top: -8px;
  }
  &---13 {
    top: -13px;
  }
  &--3 {
    top: 3px;
  }
  &--5 {
    top: 5px;
  }
  &--10 {
    top: 10px;
  }

  &--0 {
    top: 0;
  }
  &--100 {
    top: 100%;
  }
  &--50 {
    top: 50%;
  }
}
.right {
  &--0 {
    right: 0;
  }
  &---4 {
    right: -4px;
  }
  &--5 {
    right: 5px;
  }
  &---10 {
    right: -10px;
  }
  &---15 {
    right: -15px;
  }
  &--20 {
    right: 20px;
  }
  &--3 {
    right: 3px;
  }

  &--10 {
    right: 10px;
  }
}
.left {
  &---17 {
    left: -17px;
  }
  &---10 {
    left: -10px;
  }
  &--0 {
    left: 0;
  }
  &--15 {
    left: 15px;
  }
  &--10 {
    left: 10px;
  }
  &--50 {
    left: 50px;
  }
}
.bottom {
  &--0 {
    bottom: 0px;
  }
  &--100 {
    bottom: 100%;
  }

  &--5 {
    bottom: 5px;
  }
  &--10 {
    bottom: 10px;
  }
  &--60 {
    bottom: 60px;
  }
}
.object-fit {
  &--cover {
    object-fit: cover;
  }
  &--contain {
    object-fit: contain;
  }
}
.theme {
  &-active {
    border: 3px solid $theme-black-200;
  }
  &-default {
    background-color: var(--theme-default);
  }
  &-green {
    background-color: var(--theme-green);
  }
  &-blue {
    background-color: var(--theme-blue);
  }
  &-yellow {
    background-color: var(--theme-yellow);
  }
  &-purple {
    background-color: var(--theme-purple);
  }
}
/*=== FADE IN  ===*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar {
  transition: all 0.5s ease-in-out;
  .toggle-sidebar-icon {
    z-index: 9999;
  }
  .menu-item {
    // min-width: 175px;
    z-index: 999;
    .menu-name {
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      background-color: $theme-black-100;
      color: $theme-black;
      animation: fadeIn ease 0.3s;
      -webkit-animation: fadeIn ease 0.3s;
      -moz-animation: fadeIn ease 0.3s;
      -o-animation: fadeIn ease 0.3s;
      -ms-animation: fadeIn ease 0.3s;
    }
  }
}
.active {
  .mainContent {
    width: calc(100% - 60px);
  }
  .sidebar {
    max-width: 60px !important;
    min-width: 60px;
    transition: all 0.3s ease-in-out;

    .menu-name {
      svg {
        min-width: 24px;
      }
      display: none;
    }
    .menu-item {
      // min-width: 34px;
      min-width: fit-content;
      &:hover {
        // min-width: 175px;
        .menu-name {
          display: block;
        }
      }
      // &.bg--primary-100 {
      //   min-width: 34px;
      //   &:hover {
      //     min-width: 175px;
      //   }
      // }
    }
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeIn {
  animation: fadeIn ease 0.8s;
  -webkit-animation: fadeIn ease 0.8s;
  -moz-animation: fadeIn ease 0.8s;
  -o-animation: fadeIn ease 0.8s;
  -ms-animation: fadeIn ease 0.8s;
}
.fadeOut {
  animation: fadeOut ease 0.8s;
  -webkit-animation: fadeOut ease 0.8s;
  -moz-animation: fadeOut ease 0.8s;
  -o-animation: fadeOut ease 0.8s;
  -ms-animation: fadeOut ease 0.8s;
}
/*=== FADE OUT  ===*/
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.signup-calc {
  max-height: calc(100vh - 340px);
}
.mn {
  &--top-20 {
    margin-top: -20px;
  }
  &--top-25 {
    margin-top: -25px;
  }
}
.ql-container {
  height: auto !important;
}
.ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  &.ql-snow {
    border-color: $theme-black-100 !important;
  }
  .ql-picker {
    color: $theme-grey !important;
  }
  .ql-stroke {
    stroke: $theme-grey !important;
  }
}
.ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  &.ql-snow {
    border-color: $theme-black-100 !important;
  }
  .ql-editor {
    min-height: 150px;
  }
}
.new-claim-editor {
  .ql-editor {
    min-height: 150px;
    max-height: 150px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul,
    a,
    pre {
      color: $theme-surface;
    }
  }
}
// .claim-details-note {
//   max-height: calc(100vh - 400px);
//   overflow-y: auto;
// }
.text {
  &--blue {
    color: #00a8ff;
  }
  &--orange {
    color: #f06c15;
  }
}
.text--ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.verification-iframe {
  iframe {
    width: 100%;
    border: none;
    border-radius: 4px;
    min-height: 100%;
    height: 100%;
    // min-height: 492px;
  }
}
.skip-verify-btn {
  position: absolute;
  right: 16px;
  top: 65px;
  max-width: 170px;
}
.document-template {
  img {
    transition: transform 0.5s ease-in-out;
    &:hover {
      transition: transform 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }
}
// input[type="radio"] {s
//   width: 16px;
// }
input[type='radio'] {
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 2px;

  accent-color: $theme-black;
  &:checked {
    content: '';
    width: 16px;
  }
}
input[type='checkbox'] {
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 2px;
  accent-color: $theme-black;
  &:checked {
    content: '';
    width: 16px;
  }
}
.white-space {
  &--nowrap {
    white-space: nowrap;
  }
  &--wrap {
    white-space: wrap;
  }
}
.word-break {
  &--all {
    word-break: break-all;
  }
}
.p {
  &--0 {
    padding: 0 !important;
  }
}
.radius {
  &--topLeftRight {
    &--sm {
      border-radius: 6px 6px 0 0;
    }
  }
}
