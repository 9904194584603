.outerHeader {
  .navigatSec {
    margin-top: 1px;
    @media (max-width: 767px) {
      margin-left: 15px;
      .nextereLogoLong {
        max-width: 88px;
        max-height: 24px;
      }
    }
    .navigat {
      margin-top: 6px;
      @media (max-width: 767px) {
        position: absolute;
        left: -100%;
        width: 90%;
        top: 57px;
        background: $theme-white;
        height: 90vh;
        flex-direction: column;
        padding: 20px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
