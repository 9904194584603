[dropdown-element] {
  // padding: 0.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
  min-width: 120px;
}
[dropdown-caret] {
  display: flex;
  align-items: center;
  color: $theme-black;
}
[dropdown-menu-body] {
  max-height: 300px;

  overflow-y: auto;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
}
[dropdown-menu] {
  display: flex;
  flex-direction: column;
  // min-width: 150px;
  width: 100%;
  // max-width: 250px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: absolute;
  right: 0;
  // top: 100%;
  background-color: $theme-white;
  box-shadow: 0 0 4px $theme-black-100;
  z-index: 2;
  gap: 0.5rem;
}

[dropdown-menu-item] {
  display: flex;
  width: 100%;
  // padding: 0.8rem;
  border-bottom: 1px solid $theme-black-100;
  // border-radius: 0.5rem;
  color: $theme-primary;
  font-weight: 500;
  cursor: pointer;
  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:visited {
    background-color: $theme-primary-200;
  }
  &[disabled='true'] {
    &:hover {
      background-color: transparent;
    }
  }
  &:last-child {
    border: none;
  }
}
