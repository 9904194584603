@media (max-width: 767px) {
  .flex--column-xs {
    flex-direction: column;
  }
  .emptyLabelbtn {
    display: none;
  }
  .alertBox {
    min-width: 300px;
  }
  .w--full-xs {
    width: 100% !important;
  }
  .w-max--auto-xs {
    max-width: 100% !important;
  }
  .w-min--auto-xs {
    min-width: auto !important;
  }
  .h-min--auto-xs {
    min-height: auto !important;
  }
  .p {
    &--0 {
      &-xs {
        padding: 0;
      }
    }
    &--5 {
      &-xs {
        padding: 5px;
      }
    }
    &--l {
      &-2 {
        &-xs {
          padding: 2px;
        }
      }
    }
  }
  .d--flex-xs {
    display: flex !important;
  }
  .d--none-xs {
    display: none;
  }
  .gap--xs-xs {
    gap: 0.25rem;
  }
  .gap--md-xs {
    gap: 1rem;
  }
  .m--0-xs {
    margin: 0;
  }
  .m--0-xs {
    margin: 0;
  }
  .m-b--70-xs {
    margin-bottom: 70px;
  }
  .rightMainContent {
    margin-top: 30px;
    padding: 15px 5px;
  }
  .justify-content--start-xs {
    justify-content: flex-start;
  }
  .justify-content--center-xs {
    justify-content: center;
  }
  .align-items--start-xs {
    align-items: flex-start;
  }
  .align-items--center-xs {
    align-items: center;
  }
  .signatureCanvas {
    min-height: 200px;
  }
  .tableBtn {
    .tableBtnSpan {
      // overflow: auto;
      // padding-bottom: 5px;
      .btn {
        padding: 0 10px;
        border: none;
        background: none;
        min-width: auto;
        &:focus {
          color: $theme-primary;
        }
      }
    }
  }
  //table css/////
  .d--flex-xs {
    display: flex;
  }
  .justify-content--between-xs {
    justify-content: space-between;
  }
  .border--bottom--primary-xs {
    border-bottom: 1px solid $theme-primary;
    &--2 {
      border-width: 2px !important;
    }
  }
  .table {
    tr {
      td {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
      &:nth-child(odd) {
        background: $theme-black-50;
      }
      [dropdown-element] {
        min-width: auto;
      }
      [dropdown-menu] {
        min-width: 120px;
      }
    }
  }
  .pdfForm {
    max-width: 412px !important;
    padding: 10px !important;
    tr {
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      td {
        // word-break: break-all;
        text-align: justify;
        width: 100%;
        max-width: 412px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        div {
          position: relative !important;
          top: 0 !important;
          width: 100% !important;
          max-width: 412px !important;
        }
        span {
          margin-left: 0 !important;
          margin-bottom: 5px !important;
          display: block;
        }
      }
    }
    h2,
    p {
      max-width: 412px;
      width: 100% !important;
      min-height: 30px;
    }
  }
}
