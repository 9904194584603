.chatPage {
  .chatListSec {
    @media (max-width: 767px) {
      max-height: 250px;
      overflow: auto;
    }
    .searchFilter {
      .inputIcon {
        top: 8px;
        left: 8px;
      }
    }
    .chatList {
      max-height: calc(100vh - 196px);
      &.claimDetailsList {
        max-height: calc(100vh - 60px);
      }
      .chatListItem {
        .ellipsisPera {
          max-width: 185px;
        }
        &:hover {
          background-color: $theme-secondary-100;
        }
      }
    }
  }
  .chtaAvtar {
    .chatDots {
      right: 0px;
      top: 3px;
    }
  }
  .chatContent {
    max-height: calc(100vh - 206px);
    min-height: calc(100vh - 206px);
    &.claimChatSidebarContent {
      max-height: calc(100vh - 170px);
      min-height: calc(100vh - 170px);
    }
    &.claimChatPageContent {
      max-height: calc(100vh - 220px);
      min-height: calc(100vh - 220px);
    }
    .dayItem {
      left: 45%;
      top: -10px;
    }
    .incomingMsg {
      .avatarImg {
        bottom: -27px;
        left: -17px;
      }
    }
    .outgoingMsg {
      .avatarImg {
        bottom: -27px;
        right: -20px;
      }
      .row-reverse {
        flex-direction: row-reverse;
      }
    }
  }
  .dotsAction {
    .dropdownElm {
      min-width: 40px;
      dropdown-menu {
        min-width: 120px;
      }
    }
  }
}
