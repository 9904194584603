.dashboard-page {
  &-item {
    &-circle {
      .signed {
        &-consents {
          box-shadow: 0 0 0 12px $theme-grey-600;
        }
        &-eligibilities {
          box-shadow: 0 0 0 12px $theme-primary-600;
        }
      }
    }
  }
  &-chart {
    .canvasjs-chart-canvas {
      width: 100% !important;
      height: 400px;
    }
  }
  @media (max-width: 767px) {
    .grid--2-xs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .dashboard-page-item {
        padding: 5px;
        .dashboard-page-item-icon {
          min-width: 32px;
          min-height: 32px;
          max-height: 32px;
          max-width: 32px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
