input[type='checkbox'] {
  appearance: none;
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 1px solid $theme-black-400;
  line-height: 1;
  // background: $theme-primary;
  border-radius: 3px;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: pre;
    content: ' ';
  }
  &:disabled {
    opacity: 0.6;
  }
}

input[type='checkbox']:checked::before {
  content: '\2713';
  background-color: $theme-primary;

  border-radius: 3px;
}

input[type='radio'] {
  appearance: none;
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 1px solid $theme-black-400;
  border-radius: 100%;
  line-height: 1;
  // background: #aaa;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: pre;
    content: ' ';
  }
  &:disabled {
    opacity: 0.6;
  }
}

input[type='radio']:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: $theme-primary;
  top: 2px;
  left: 2px;
  text-align: center;
  position: absolute;
}
.disableColor {
  input[type='radio']:checked::before {
    content: '';
    background-color: $theme-danger !important;
  }
}
