.manageKeyTabConfig {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}

.myAccountContent {
  .manageKeyTab {
    min-height: calc(100vh - 149px);
    max-height: calc(100vh - 149px);
  }
}
