*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html,
body {
  height: 100%;
  // font-size: 12px;
  font-size: 14px;
  color: $theme-grey;
}
.font-family {
  &--Playfair {
    font-family: Playfair Display, sans-serif;
  }
}
.preeCode {
  .fontF {
    font-family: 'Merriweather', serif !important;
  }
}
#root {
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}

img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
input,
select {
  accent-color: $theme-primary;
  &:focus {
    outline: none;
    border-color: $theme-primary;
    box-shadow: 0 0 5px rgba($theme-primary, 0.3);
  }
  &[type='radio'] {
    &:focus {
      box-shadow: none;
    }
  }
  &.no-focus {
    &:focus {
      outline: none;
      border-color: $theme-transparent;
      box-shadow: none;
    }
  }
  &.disableColor {
    accent-color: $theme-primary !important;
  }
}
button {
  border: none;
  box-shadow: none;
  outline: none;
  inset: unset;
}

[data-link-hover-primary] {
  &:hover {
    background-color: $theme-primary;
    transition: background-color 0.4s ease-out;
  }
}
[data-link-hover] {
  &:hover {
    background-color: $theme-primary-200;
    border-color: $theme-primary;
    transition: background-color 0.4s ease-out;
  }
}
[data-link-hover-primary] {
  &:hover {
    background-color: $theme-primary;
    transition: background-color 0.4s ease-out;
  }
}
[data-link-hover-black-200] {
  &:hover {
    background-color: $theme-black-200;
    transition: background-color 0.4s ease-out;
  }
}
[data-link-hover-secondary] {
  &:hover {
    background-color: $theme-secondary;
    transition: background-color 0.4s ease-out;
    color: $theme-white;
  }
}
.opacity-5 {
  opacity: 0.5;
}
.dashboard-table {
  &.box--shadow {
    box-shadow: none;
  }
  .table--responsive {
    height: 40vh;
  }
}
.canvasjs-chart-credit {
  display: none;
}
.PSPDFKit-Annotation-Widget-Required:invalid {
  border: 2px solid red;
}

/* This matches filled required fields. */
.PSPDFKit-Annotation-Widget-Required {
  border: 1px solid red;
}
.d--none {
  display: none !important;
}
.broken-img {
  background-image: url('../assets/images/nextere-logo.png');
}
.react-pdf-customclass {
  width: 794px;
  margin: 10px auto;
  padding: 20px;
  max-width: 796px;
  .react-pdf__Page {
    background-color: #fff;
  }
}
.sidePdfDetails {
  max-height: calc(100vh - 61px);
}
.text--blue {
  color: rgb(35, 2, 247);
}
